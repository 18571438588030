import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { AppConfig } from './types/config';

Vue.config.productionTip = false;

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'font-awesome/css/font-awesome.min.css';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(require('vue-moment'));

import Snotify from 'vue-snotify';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'vue2-datepicker/index.css';

Vue.use(Vuetify);
const vuetify = new Vuetify({
  icons: {
    iconfont: 'md',
  },
});

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

Vue.use(Snotify, {
  toast: {
    position: 'rightTop',
    showProgressBar: false,
  },
});

import './assets/scss/main.scss';

// Function to load configuration
async function loadConfig(): Promise<AppConfig> {
  const response = await fetch('/config.json');
  if (!response.ok) {
    throw new Error('Failed to load configuration');
  }
  return response.json();
}

// Load configuration before creating the Vue instance
loadConfig()
  .then(config => {
    console.log('Config Loaded:', config);
    // Store the configuration in the Vue prototype
    Vue.prototype.$config = config;

    new Vue({
      router,
      store,
      vuetify: new Vuetify({icons: {
        iconfont: 'fa4',
      }}),
      render: h => h(App),
    }).$mount('#app');
  })
  .catch(error => {
    console.error(error);
    // Handle error (e.g., provide default config or show an error message)
    Vue.prototype.$config = {
      SERVER_ADDRESS: '/mapi', // Default config
      NODE_ENV: 'development',
    };

    new Vue({
      router,
      store,
      vuetify: new Vuetify({icons: {
        iconfont: 'fa4',
      }}),
      render: h => h(App),
    }).$mount('#app');
  });
