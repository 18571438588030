import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userType: ""
  },
  mutations: {
    setUserType(state, userType) {
      state.userType = userType;
    },
  },
  actions: {
    updateUserType({ commit }, userType) {
      commit('setUserType', userType);
    },
  },
  modules: {
  },
  getters: {
    userType: (state) => state.userType, // Add a getter to access userType
  },
})
