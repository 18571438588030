import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component :() => import('@/components/login/login.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component :() => import('@/components/dashboard/dashboard.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    component :() => import('@/components/users/users.vue'),
  }
]


const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  let userInfo = localStorage.getItem('UserInfo') || ''
  if(userInfo == '' && to.path !== "/") {
    router.push({ path: '/'})
  }

  //eslint-disable-next-line
  document.title  = 'T5-Phoenix';
    return next()
    // Specify the current path as the customState parameter, meaning it
    // will be returned to the application after auth
    // auth.login({ target: to.path });

  })

export default router
