
import { Vue, Component } from "vue-property-decorator";
@Component
export default class AppComponent extends Vue {

showHeader() {
    if(this.$route.name == 'Login') {
      return false
    }
    return true
}
}
  
